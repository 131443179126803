import * as types from './Types'

const initialState = {
    loadBikeName: [],
    loadBikeModel: [],
    loadBikeFuel: [],
    loadBikeVariant: [],
    loadBikeRTO: [],
    StateName: [],
    CityName: [],
    Relation: [],
    Occupation: [],
    PreviousInsurer: [],
    Insurer: [],
    Financer: {},
    BikeQuotes: [],
    PropsalRes: [],
    storeQuotesRequest: {},
    StoreMakeData: {},
    StoreModelData: {},
    addRiders: {},
    StoreFuelData: {},
    StoreVariant: {},
    storeRtoData: {},
    selectedProduct: {},
    storeYearData: {},
    SelectedBike: {},
    getGodigitKyc: {},
    isKycDone: {},
    KYCResStore: {},

    ownerData: {},
    PersonalInfo: {},
    VehicleInfoBike: {},
    AddInfo: {},
    vehicleAddInfo: {},
    loading: true
}

export const BikeReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_BIKE_MAKES:
            return {
                ...state,
                loadBikeName: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_MODEL:
            return {
                ...state,
                loadBikeModel: payload,
                loading: false
            }
        case types.GET_FUEL:
            return {
                ...state,
                loadBikeFuel: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_VARIANT:
            return {
                ...state,
                loadBikeVariant: payload,
                loading: false
            }
        case types.GET_ALL_BIKE_RTO:
            return {
                ...state,
                loadBikeRTO: payload,
                loading: false
            }
        // *********************************************************************** for Store  ******
        case types.ADD_BIKE_MAKE:
            return {
                ...state,
                StoreMakeData: payload,
                loading: false
            }
        case types.ADD_BIKE_MODEL:
            return {
                ...state,
                StoreModelData: payload,
                loading: false
            }
        case types.ADD_BIKE_FUEL:
            return {
                ...state,
                StoreFuelData: payload,
                loading: false
            }
        case types.ADD_BIKE_VARIANT:
            return {
                ...state,
                StoreVariant: payload,
                loading: false
            }
        case types.ADD_BIKE_RTO:
            return {
                ...state,
                storeRtoData: payload,
                loading: false
            }
        case types.SELECTED_INSURARER_DETAILS:
            return {
                ...state,
                selectedProduct: payload,
                loading: false
            }
        case types.QUOTES_REQUESTED_DATA:
            return {
                ...state,
                storeQuotesRequest: payload,
                loading: true
            }
        case types.GET_PROPOSAL:
            return {
                ...state,
                PropsalRes: payload,
                loading: true
            }
        case types.ADD_BIKE_YEAR:
            return {
                ...state,
                storeYearData: payload,
                loading: false
            }
        // *********************************************************************** for Other  ******
        case types.OWNWE_DETAIL:
            return {
                ...state,
                ownerData: payload,
                loading: false
            }
        case types.PERSONAL_DETAILS:
            return {
                ...state,
                PersonalInfo: payload,
                loading: false
            }
        case types.VEHICLE_INFO_BIKE:
            return {
                ...state,
                VehicleInfoBike: payload,
                loading: false
            }
        case types.GET_PREVIOUS_INSURER:
            return {
                ...state,
                PreviousInsurer: payload,
                loading: false
            }
        case types.ADDRESS_DETAIL:
            return {
                ...state,
                AddInfo: payload,
                loading: false
            }
        case types.GET_ALL_CITY:
            return {
                ...state,
                CityName: payload,
                loading: false
            }
        case types.GET_ALL_RELATIONSHIP:
            return {
                ...state,
                Relation: payload,
                loading: false
            }
        case types.GET_ALL_OCCUPATIONS:
            return {
                ...state,
                Occupation: payload,
                loading: false
            }
        case types.GET_ALL_FINANCER:
            return {
                ...state,
                Financer: payload,
                loading: false
            }
        case types.GET_ALL_INSURER:
            return {
                ...state,
                Insurer: payload,
                loading: false
            }
        case types.GET_ALL_STATE:
            return {
                ...state,
                StateName: payload,
                loading: false
            }
        // *********************************************************************** Quotes  ******
        case types.GET_BIKE_QUOTES:
            return {
                ...state,
                BikeQuotes: payload,
                loading: false
            }
        case types.SELECTED_BIKE_DATA:
            return {
                ...state,
                SelectedBike: payload,
                loading: true
            }
        case types.ADD_RIDER:
            return {
                ...state,
                addRiders: payload,
                loading: false
            }
        case types.GET_KYC_DETAIL:
            return {
                ...state,
                getGodigitKyc: payload,
                loading: true
            }
        case types.ALL_GET_KYC_DETAIL:
            return {
                ...state,
                KYCResStore: payload,
                loading: true
            }
        case types.GET_GODIGIT_KYC_CONTROLLER:
            return {
                ...state,
                isKycDone: payload,
                loading: true
            }
        case types.EMPTY_JOURNEY:
            return {
                ...state,
                StoreMakeData: initialState,
                StoreModelData: initialState,
                StoreFuelData: initialState,
                StoreVariant: initialState,
                storeRtoData: initialState,
                storeYearData: initialState,
                BikeQuotes: initialState
            }

        case types.RESET_PROPOSAL_RES: return {
            ...state
            // KYCResStore: initialState.KYCResStore
        }
        default:
            return state
    }
}
export default BikeReducers